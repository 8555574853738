import React, { FC } from 'react';
import SpectarLayout from '../../../components/PagesLayouts/SpectarLayout';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import './ProjectGallery.scss';
import img1 from '../../../assets/img/CaseStudy/Spectar/1.jpg';
import img2 from '../../../assets/img/CaseStudy/Spectar/2.jpg';
import img3 from '../../../assets/img/CaseStudy/Spectar/3.jpg';
import img4 from '../../../assets/img/CaseStudy/Spectar/4.jpg';
import img5 from '../../../assets/img/CaseStudy/Spectar/5.jpg';
import Card from '../../../components/pagesComponents/CaseStudy/Card';
import FixedButton from '../../../components/common/FixedButton';

const ProjectGallery: FC = () => {
    return (
        <SpectarLayout>
            <div className="scope-of-project__video spectar__gallery">
                <div className="scope-of-project__cards">
                    <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb">
                        <Card img={img1} size="large" imageType="large" />
                        <Card img={img2} size="small" imageType="small" />
                    </div>
                    <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb-60">
                        <Card img={img3} size="small" imageType="small" />
                        <Card img={img4} size="large" imageType="large" />
                    </div>
                    <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb-60">
                        <Card img={img5} size="wide" imageType="wide" />
                    </div>
                </div>
            </div>
            <div className="scope-of-project__body scope-of-project__body--mw">
                <Links />
            </div>
            <FixedButton />
        </SpectarLayout>
    );
};

export default ProjectGallery;
