import React, { FC, useState } from 'react';
import './Card.scss';
import { ICardProps } from '../../../../types/types';
import Button from '../../../common/Button';
import twoArrows from '../../../../assets/img/CaseStudy/Walmart/two-arrows.svg';

const Card: FC<ICardProps> = (props) => {
    const [showFullImage, setShowFullImage] = useState<boolean>(false);
    const openFullSizeImage = () => setShowFullImage(!showFullImage);

    return (
        <div data-testid="card" className={`card card--${props.size}`}>
            <img src={props.img} alt="" className={`card__img card__img--${props.imageType}`} />
            <div className="card__btn" onClick={openFullSizeImage}>
                <Button icon={twoArrows} type="circle-blue" iconType="large">
                    Expand
                </Button>
            </div>
            {showFullImage && (
                <div className="card__wrapper">
                    <span className="card__button" onClick={openFullSizeImage}>
                        &#10005;
                    </span>
                    <img src={props.img} alt="" className="card__fullsize-img" />
                </div>
            )}
        </div>
    );
};

export default Card;
